/*
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');
*/


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Arimo',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.css');



*{
  font-family: 'Arimo', sans-serif !important;
}
.h-screen {
  height: 100vh;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}


.loader-parent{
  position: fixed;
  inset: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 9999 !important;
  background-color: rgba(255, 255, 255, 0.664);
  /*background-color: rgba(43, 43, 43, 0.664);*/
  /*background-color: white;*/
}

.w-50{
  width: 50%;
}
.w-100{
  width: 100%;
}

i{
  font-size: 1.4rem;
  cursor: pointer;
}

input{
   border : 1px solid rgb(79, 83, 83) !important;
}

/*
.container{
  height: 85vh !important;
}
*/

/*.mantine-xoj88x{
  height: 100% !important;
  overflow: scroll !important;
}*/

.auth{
  background-color: #F0F0F0;
}

.my-custom-table th {
  background-color: #fffdfd;
  /* Color de fondo para encabezados */
  border-bottom: 1px solid #ddd;
  /* Línea divisoria en la parte inferior de las celdas del encabezado */
  text-align: left;
  /* Centra el texto */
  background-color: hsl(0, 0%, 100%);
  /* Color de fondo */
  color: rgb(0, 0, 0);
  /* Color del texto */
  padding-left: 5px;
  padding-bottom: 5px;
}

.my-custom-table td {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  /* Define el color y grosor de la línea divisiva en la parte superior e inferior */
  border-left: none;
  border-right: none;
  padding-left: 5px;
  text-align: left;
}

.my-custom-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
  /* Fondo de filas impares */
}

.my-custom-table tbody tr:hover {
  background-color: #e0e0e0;
  /* Color de fondo al pasar el ratón sobre una fila */
}

.native-select-container {
  width: 60%;
  position: relative;
}

.native-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32'%3E%3Cpath d='M16 24l-8-8 1.41-1.41L16 21.17l6.59-6.58L24 16z'/%3E%3C/svg%3E") no-repeat right 10px center/10px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.native-select:focus {
  outline: none;
  border-color: #007bff;
}

.AccordionControl {
  background-color: #00497A; /* Cambia este valor al color que prefieras */
  color: white;
  border-top-left-radius: 4px; /* Ajusta el valor según el radio de borde deseado */
  border-top-right-radius: 4px;

}

.AccordionControlUsuariosForm {
  background-color: #00497A; /* Cambia este valor al color que prefieras */
  color: white;
  width: 300px;
}

.AccordionControlTabla {
  background-color: #ffffff; /* Cambia este valor al color que prefieras */
  color: rgb(40, 40, 40);
}

.AccordionControl:hover {
  background-color: #00497A; /* Mantener el mismo color de fondo en hover */
}

.AccordionControlUsuariosForm:hover {
  background-color: #00497A; /* Mantener el mismo color de fondo en hover */
}

.AccordionControl2 {
  background-color: white; /* Cambia este valor al color que prefieras */
  color: black;
  border: 1px solid black; /* Set the border to 1px width solid black */
  margin: 5px;
  width: 100%;
}

.AccordionControl2:hover {
  background-color: white; /* Mantener el mismo color de fondo en hover */
}

.CardTituloCatalogos {
  margin-bottom: 15px;
  border: 1px solid rgb(216, 216, 216);
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */}

.AccordionPanel {
  background-color: white;
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  margin-bottom: 15px;
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */
  border-bottom-left-radius: 4px; /* Ajusta el valor según el radio de borde deseado */
  border-bottom-right-radius: 4px;

}

.CardTituloReportes {
  margin-bottom: 10px;
  border: 1px solid rgb(216, 216, 216);
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */

}

.CardTablas {
  margin-bottom: 10px;
  border: 1px solid rgb(216, 216, 216);
  /*box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */

}


.NavBarCard {
/*  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);*/ /* Añadir sombra */
}




.HeaderCard {
/*  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Añadir sombra */
}

.textarea-fullwidth {
  width: 100%;
}

@media (max-width: 768px) {
  .textarea-fullwidth {
    width: 100%;
  }
}

.whiteBurger path {
  stroke: white;
}

.caja-350 {
  width: 350px;
}

.caja-grande {
  width: 300px;
}

.caja-estandar {
  width: 200px;
}

.caja-chica {
  width: 150px;
}

.caja-sintomas {
  width: 100%;
  max-width: 635px;
}

@media (max-width: 768px) {
  .group-no-wrap {
    flex-direction: column;
  }

  .caja-grande,
  .caja-sintomas,
  .caja-estandar,
  .caja-chica,
  .caja-200,
  .caja-280,
  .caja-350,
  .caja-100,
  .caja-400,
  .caja-450,
  .caja-600,
  .caja-80p,
  .caja-75p,
  .caja-20p {
    width: 100%;
  }
}

.Filtro {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-bottom: 15px;
  justify-content: space-between;
}

.Filtro .caja-150:last-child {
  margin-right: 0;
  /* Sin margen derecho para el último elemento en cada fila */
}

/* Media Query para pantallas pequeñas */
@media (max-width: 768px) {
  .FiltroActividades {
    flex-direction: column;
  }

  .caja-150 {
    width: 100%;
    margin-right: 0;
    /* Eliminar margen derecho en pantallas pequeñas */
    margin-bottom: 15px;
    /* Añadir margen inferior para espacio entre elementos en columna */
  }

  .FiltroActividades .caja-150:last-child {
    margin-bottom: 0;
    /* Eliminar margen inferior del último elemento */
  }
}

.caja-150 {
  flex: 1;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
  /* Espacio entre elementos */
}

.FiltroActividades .caja-150:last-child {
  margin-right: 0;
  /* Sin margen derecho para el último elemento en cada fila */
}

.caja-150 .mantine-Input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}